import './index.css';
import './App.css';
import logoUrl from './assets/do_logo.png';

export function App() {
  return (
<div className="min-h-screen bg-black text-white">
  <section className="w-screen py-12">
    <div className="max-w-5xl mx-auto px-4 flex flex-col items-center justify-center text-center">
      <img src={logoUrl} alt="dronze.net" className='w-[150] md:w-[300]' />


      <h2 className="text-6xl font-title">deeporb.net</h2>
      <p className="mt-2 mb-4 text-gray-100 lexend-300 text-2xl">Every person deserves access to intelligence.</p>
      <div>pooka@deeporb.net</div>
    </div>
  </section>
</div>

  );
}
